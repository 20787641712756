/**
 * @module FechasAdministrativas
 * @author @tirsomartinezreyes
 * @version 0.0.1
 * @description Módulo para el cálculo de fechas administrativas tomando en consideración los días naturales y hábiles
 *
 * @changelog 0.0.1 - 22/09/24 - Primera versión del módulo
 *
 * @businessLogic
 * 1.- Un día habil es un día que no se encuentra en la lista de días no hábiles
 * 2.- Un día natural es un dia normal, sin importar si es hábil o no
 * 3.- El inicio de un día hábil es a las 09:00 horas (Esto no es relevante para el cómputo de los días)
 * 4.- La hora limite para un trámite es las 6:00 pm del día límite (Esto no es relevante para el cómputo de los días)
 * 5.- No importa si el cómputo es con días hábiles o no hábiles, el inicio del cómputo se dá siempre en el siguiente día hábil a la fecha de entrada el trámite
 * 5.- No importa si el cómputo es con días hábiles o no hábiles, el resultado del cómputo se dá siempre en el siguiente día hábil a la fecha aritmética de días habiles
 * 6.- La zona horaria es la de la CDMX con un offset de -6 horas con UTC
 * 7.- Es de interés conocer las fechas no hábiles contempladas en el cómputo desde la fecha de inicio hasta la fecha de resultado
 */

const CFechaAdministrativaDiaNatural = 1000 * 60 * 60 * 24
const CFechaAdministrativaInicioHoraHabil = 1000 * 60 * 60 * 9 // 09:00 de la Ciudad de México
const CFechaAdministrativaFinHoraHabil = 1000 * 60 * 60 * 18 // 18:00 de la Ciudad de México

export enum EFechaAdministrativaOperacion {
	SUMAR_DIAS_HABILES = 'SUMAR_DIAS_HABILES',
	SUMAR_DIAS_NATURALES = 'SUMAR_DIAS_NATURALES'
}

interface IFechaAdministratibaBase {
	fechaEntrada: number //Fecha proporcionada como el momento de ingreos del trámite para el cálculo de los días
	operacion: EFechaAdministrativaOperacion //Operación a realizar
	dias: number //Días a sumar, si son naturales o hábiles depende del tipo de operación
}

export interface IFechaAdministrativaSolicitud extends IFechaAdministratibaBase {
	diasNoHabiles: string | number[] //Lista de días no hábiles en formato de cadena separada por comas o arreglo de timestamps en UTC
}

export interface IFechaAdministrativaRespuesta extends IFechaAdministratibaBase {
	fechaInicio: number //Fecha de inicio del cálculo, siempre es un día hábil
	fechaResultado: number //Fecha de resultado del cálculo, dependera de la operación
	fechaEntradaTexto: string //Fecha de entrada en formato de cadena
	fechaInicioTexto: string //Fecha de inicio en formato de cadena
	fechaResultadoTexto: string //Fecha de resultado en formato de cadena
	diasNoHabilesContemplados: number[] //Lista de días no hábiles contemplados en el cálculo
}

export const calcularFechaAdministrativa = (solicitud: IFechaAdministrativaSolicitud): IFechaAdministrativaRespuesta => {
	const _fechaEntrada = solicitud.fechaEntrada
	const diasNoHabiles: number[] = typeof solicitud.diasNoHabiles === 'string' ? convertirCadenaFechasEnArray(solicitud.diasNoHabiles) : solicitud.diasNoHabiles
	const _fechaInicio = siguienteDiaHabil(solicitud.fechaEntrada, diasNoHabiles)
	let _fechaResultado = solicitud.fechaEntrada
	const _diasNoHabilesContemplados: number[] = []

	//DIAS NATURALES
	if (solicitud.operacion == EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES) {
		_fechaResultado = _fechaInicio + CFechaAdministrativaDiaNatural * solicitud.dias
		_fechaResultado = normalizarFecha(_fechaResultado) + CFechaAdministrativaFinHoraHabil
	}

	//DIAS HÁBILES
	if (solicitud.operacion == EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES) {
		const diasHabiles: number[] = []
		let counter = 0

		let diaEvaluar = _fechaInicio
		while (diasHabiles.length < solicitud.dias) {
			diaEvaluar += CFechaAdministrativaDiaNatural

			if (esDiaHabil(diaEvaluar, diasNoHabiles)) {
				diasHabiles.push(diaEvaluar)
			} else {
				_diasNoHabilesContemplados.push(diaEvaluar)
			}
			counter++
			if (counter > 10000) {
				break
			}
		}
		_fechaResultado = normalizarFecha(diasHabiles[diasHabiles.length - 1]) + CFechaAdministrativaFinHoraHabil
	}

	//AJUSTE DE FECHA DE RESULTADO A UN DÍA HáBIL
	//Si la fecha de resultado no es un día hábil, se debe buscar el siguiente día hábil + se contempla la hora final
	if (esDiaHabil(_fechaResultado, diasNoHabiles)) {
		_fechaResultado = normalizarFecha(_fechaResultado) + CFechaAdministrativaFinHoraHabil
	} else {
		const _siguienteDiaHabil = siguienteDiaHabil(_fechaResultado, diasNoHabiles)
		const diasNoHabilesEntreFechas = diasNoHabilesEntreDosFechas(_fechaResultado, _siguienteDiaHabil, diasNoHabiles)
		_fechaResultado = normalizarFecha(siguienteDiaHabil(_fechaResultado, diasNoHabiles)) + CFechaAdministrativaFinHoraHabil
		_diasNoHabilesContemplados.push(...diasNoHabilesEntreFechas)
	}

	//RESPUESTA
	const respuesta: IFechaAdministrativaRespuesta = {
		fechaEntrada: _fechaEntrada,
		operacion: solicitud.operacion,
		dias: solicitud.dias,
		fechaInicio: _fechaInicio,
		fechaResultado: _fechaResultado,
		fechaEntradaTexto: obtenerFechaComoCadena(_fechaEntrada),
		fechaInicioTexto: obtenerFechaComoCadena(_fechaInicio),
		fechaResultadoTexto: obtenerFechaComoCadena(_fechaResultado),
		diasNoHabilesContemplados: _diasNoHabilesContemplados
	}

	return respuesta
}

const convertirCadenaFechasEnArray = (entrada: string | number[]): number[] => {
	if (entrada instanceof Array) {
		return entrada
	}
	const fechas = entrada.split(',').map(f => parseInt(f))
	return fechas
}

function obtenerFechaComoCadena(millis: number, short: boolean = false): string {
	let response = ''
	if (typeof millis == 'string') {
		millis = parseInt(millis)
	}
	if (millis) {
		const tmp = new Date(millis)
		const year: number = tmp.getFullYear()
		const day = (tmp.getDate() < 10 ? '0' + tmp.getDate() : tmp.getDate()).toString()
		const hours = (tmp.getHours() < 10 ? '0' + tmp.getHours() : tmp.getHours()).toString()
		const minutes = (tmp.getMinutes() < 10 ? '0' + tmp.getMinutes() : tmp.getMinutes()).toString()
		const seconds = (tmp.getSeconds() < 10 ? '0' + tmp.getSeconds() : tmp.getSeconds()).toString()

		const monthNames = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
		const monthName = monthNames[tmp.getMonth()]
		if (short) {
			response = `${day}/${monthName}/${year}`
		} else {
			response = `${day}/${monthName}/${year} ${hours}:${minutes}:${seconds}`
		}
	}
	return response
}

const normalizarFecha = (fecha: number): number => {
	const fechaBase = new Date(fecha)
	fechaBase.setHours(0, 0, 0, 0)
	return fechaBase.getTime()
}

const esDiaHabil = (fecha: number, diasNoHabiles: number[]): boolean => {
	let respuesta: boolean = true
	const fechaNormalizada = normalizarFecha(fecha)
	if (diasNoHabiles.includes(fechaNormalizada)) {
		respuesta = false
	}
	return respuesta
}

const siguienteDiaHabil = (fecha: number, diasNoHabiles: number[]): number => {
	for (let i = 1; i <= 365; i++) {
		const siguienteDia = normalizarFecha(fecha + CFechaAdministrativaDiaNatural * i)
		if (esDiaHabil(siguienteDia, diasNoHabiles)) {
			return siguienteDia + CFechaAdministrativaInicioHoraHabil
		}
	}
	return normalizarFecha(fecha + CFechaAdministrativaDiaNatural) + CFechaAdministrativaInicioHoraHabil
}

const diasNoHabilesEntreDosFechas = (fechaInicial: number, fechaFinal: number, diasNoHabiles: number[]): number[] => {
	const respuesta: number[] = []

	const inicio = normalizarFecha(fechaInicial)
	const fin = normalizarFecha(fechaFinal)
	for (let diaVerificar = inicio; diaVerificar <= fin; diaVerificar += CFechaAdministrativaDiaNatural) {
		if (!esDiaHabil(diaVerificar, diasNoHabiles)) {
			respuesta.push(diaVerificar)
		}
	}
	return respuesta
}

export const getTipoDiasPorOperacion = (operacion?: EFechaAdministrativaOperacion): string => {
	let respuesta = ''
	switch (operacion) {
		case EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES:
			respuesta = 'hábiles'
			break
		case EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES:
			respuesta = 'naturales'
			break
		default:
			respuesta = 'desconocidos'
			break
	}
	return respuesta
}
