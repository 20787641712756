import 'reflect-metadata'
import Config, { configType } from '../Constants/Config'
import { ExceptionUtilities } from '../Modules/Utilities'
import TypeContainer from '../Types/Container'
import { EGraphqlResponseStatus, IGraphqlResponse, getStatusMessage } from '../Types/Graphql'
import { IREQUEST, grapqhlResponseRequestType } from '../graphql/GrapqhlQueriesBase'
import SpyProxy, { ISelectionSet } from './graphql/spyProxy'
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'

export class IGetAccessToken {
	getUserAccessToken(): string {
		throw new Error('Implemented by typedi Method not implemented.')
	}
}
export interface IErrorHandler {
	retryFun?: Promise<unknown>
	msg?: string
	jwt?: string
	signal?: AbortSignal
	ignoreErrors?: boolean
}

export default class GrapqhlApi {
	private static Url: string = Config.ENVIRONMENTS[Config.ENVIRONMENT as keyof configType['ENVIRONMENTS']].API_SERVICES.GRAPQLH.URL
	private static accessToken(): string {
		//console.log('get service')
		return TypeContainer.get<IGetAccessToken>('IGetAccessToken').getUserAccessToken()
	}

	public static setUrl(url: string) {
		this.Url = url
	}

	private static setVariables<I extends Partial<IREQUEST> | undefined, O extends Partial<IGraphqlResponse> | undefined>(query: grapqhlResponseRequestType<I, O>): grapqhlResponseRequestType<I, O> {
		if ((query.interpolatedArgs ?? '') == '') {
			return query
		}
		const interpolatedArgs = JSON.parse(query.interpolatedArgs!)
		if (interpolatedArgs != undefined) {
			for (const key of Object.keys(interpolatedArgs)) {
				const value = (query?.request?.args as any)[key]
				if (value == undefined) {
					interpolatedArgs[key] = undefined
				}
				interpolatedArgs[key] = value
			}
		}
		//console.log(interpolatedArgs)
		const interpolatedArgsJson = JSON.stringify(interpolatedArgs)
		const unquoted = interpolatedArgsJson.replace(/"([^"]+)":/g, '$1:')
		const _copy: grapqhlResponseRequestType<I, O> = JSON.parse(JSON.stringify(query))
		_copy.query = _copy.query.replace(query.interpolatedArgs!, unquoted)
		delete _copy.request!.args
		return _copy
	}

	// eslint-disable-next-line @typescript-eslint/no-duplicate-type-constituents
	private static async _doFetch<REQUEST extends Partial<IREQUEST> | undefined, RESPONSE extends IGraphqlResponse | undefined>(
		queryType: grapqhlResponseRequestType<REQUEST, RESPONSE>,
		options?: IErrorHandler
	): Promise<RESPONSE> {
		queryType = GrapqhlApi.setVariables(queryType)

		let variables = queryType.request
		if (variables != undefined && Object.keys(variables).length == 0) {
			variables = undefined
		}
		if (options == undefined) {
			options = {}
		}
		if (queryType.jwt != undefined) {
			options.jwt = queryType.jwt
		}
		const graphql: string = JSON.stringify({
			query: queryType.query,
			variables: variables ?? { args: {} }
		})
		return await this.fetchData<RESPONSE>(
			{
				data: graphql
			},
			options
		)
	}

	private static async _doCustomFetch<REQUEST extends Partial<IREQUEST> | undefined, RESPONSE extends IGraphqlResponse | undefined, T>(
		queryType: grapqhlResponseRequestType<REQUEST, RESPONSE>,
		selectionSet: (r: RESPONSE, isDummy: boolean) => T,
		options?: IErrorHandler & { ignorePrefix?: string }
	): Promise<T> {
		queryType = GrapqhlApi.setVariables(queryType)

		let variables: REQUEST | undefined = queryType.request
		if (variables != undefined && Object.keys(variables).length == 0) {
			variables = undefined
		}
		const spyProxy = new SpyProxy({ addMetaData: true })
		const dummyObj = spyProxy.obj

		selectionSet(dummyObj, true)
		const querySelection: ISelectionSet = spyProxy.getUsed({ ignorePrefix: options?.ignorePrefix })
		if (querySelection != undefined) {
			if (variables == undefined) {
				variables = {} as REQUEST
			}
			if (variables!.args == undefined) {
				variables!.args = {}
			}
			variables!.args.querySelection = JSON.stringify(querySelection)
		}
		const graphql: string = JSON.stringify({
			query: queryType.simpleQuery.replace('<selection/>', spyProxy.getSelectionQuery()),
			variables: variables
			//querySelection: querySelection
		})
		let response: Partial<RESPONSE> | undefined = {}
		try {
			response = await this.fetchData<RESPONSE>(
				{
					data: graphql
				},
				options
			)
		} catch (err) {
			throw new Error(ExceptionUtilities.getExceptionMessage(err))
		}
		return selectionSet(response as RESPONSE, false)
	}

	private static async _doStringQueryFetch(stringQuery: string, options?: IErrorHandler): Promise<unknown> {
		return await this.fetchData(
			{
				data: JSON.stringify({ query: stringQuery })
			},
			options
		)
	}

	public static async doStringQueryFetch(stringQuery: string, options?: IErrorHandler): Promise<unknown> {
		try {
			return await this._doStringQueryFetch(stringQuery, options)
		} catch (err) {
			throw new Error(ExceptionUtilities.getExceptionMessage(err))
		}
	}

	public static async doCustomFetch<REQUEST extends Partial<IREQUEST> | undefined, RESPONSE extends IGraphqlResponse | undefined, T>(
		queryType: grapqhlResponseRequestType<REQUEST, RESPONSE>,
		selectionSet: (r: RESPONSE) => T,
		options?: IErrorHandler & { ignorePrefix?: string }
	): Promise<T | undefined> {
		try {
			return await this._doCustomFetch(queryType, selectionSet, options)
		} catch (err) {
			return undefined
		}
	}

	public static async doFetch<REQUEST extends Partial<IREQUEST> | undefined, RESPONSE extends IGraphqlResponse | undefined>(
		queryType: grapqhlResponseRequestType<REQUEST, RESPONSE>,
		options?: IErrorHandler
	): Promise<RESPONSE> {
		try {
			return await this._doFetch(queryType, options)
		} catch (err) {
			if ((err as Error).message == '500: Unknown Error') {
				return {
					metaData: {
						status: EGraphqlResponseStatus.INTERNAL_ERROR,
						message: getStatusMessage(EGraphqlResponseStatus.INTERNAL_ERROR).statusNormalizedName
					}
				} as RESPONSE
			}
			return {
				metaData: {
					status: EGraphqlResponseStatus.INTERNAL_ERROR,
					message: ExceptionUtilities.getExceptionMessage(err)
				}
			} as RESPONSE
		}
	}

	private static async fetchData<RESPONSE extends IGraphqlResponse | undefined>(requestOptions: AxiosRequestConfig, options?: IErrorHandler): Promise<RESPONSE> {
		//Garantizar las cabeceras
		if (requestOptions.headers == undefined) {
			requestOptions.headers = {}
		}
		requestOptions.headers['Content-Type'] = 'application/json'
		let jwt = ''
		if (options?.jwt != undefined && typeof options.jwt == 'string') {
			jwt = (options?.jwt ?? '')?.trim()
		}
		requestOptions.headers['authorization'] = jwt != '' ? jwt : this.accessToken()
		//requestOptions.redirect = 'follow'
		if (options?.signal != undefined) {
			requestOptions.signal = options?.signal
		}
		if (requestOptions.data == undefined) {
			requestOptions.data = JSON.stringify({
				args: {}
			})
		}
		axios.defaults.timeout = Config.GENERAL.MAX_FETCH_TIMEOUT

		requestOptions.method = 'post'
		requestOptions.responseType = 'json'
		requestOptions.url = this.Url
		requestOptions.timeout = Config.GENERAL.MAX_FETCH_TIMEOUT

		let fetchResponse: AxiosResponse<{ data: RESPONSE; errors: { message?: string }[] }, any> | undefined = undefined
		fetchResponse = await axios(requestOptions)
			.then(function (response) {
				return response
			})
			.catch(function (error) {
				const response: AxiosResponse<any, any> = {} as AxiosResponse<any, any>
				response.status = error.response.status
				response.statusText = error.message
				return response
			})
		if (fetchResponse?.status != 200) {
			switch (fetchResponse?.status) {
				case 401:
					throw new Error(getStatusMessage(EGraphqlResponseStatus.UNAUTHORIZED).statusNormalizedName)
				case 400:
					throw new Error(getStatusMessage(EGraphqlResponseStatus.BAD_REQUEST).statusNormalizedName)
				case 403:
					throw new Error(getStatusMessage(EGraphqlResponseStatus.FORBIDDEN).statusNormalizedName)
				case 404:
					throw new Error(getStatusMessage(EGraphqlResponseStatus.NOT_FOUND).statusNormalizedName)
				case 500:
					throw new Error(getStatusMessage(EGraphqlResponseStatus.INTERNAL_ERROR).statusNormalizedName)
				case 599:
					throw new Error(getStatusMessage(EGraphqlResponseStatus.NET_WORK_ERROR).statusNormalizedName)
				default:
					throw new Error('Unknown Error status: ' + fetchResponse?.status + ' ' + fetchResponse?.statusText)
			}
		}
		const fetchResponseJson = fetchResponse.data
		//console.log('fetchResponseJson', fetchResponseJson)
		const data = Object.values(fetchResponseJson?.data || {})[0]
		if (data?.metaData?.status !== EGraphqlResponseStatus.SUCCESS) {
			let errorMessage = data?.metaData?.message ?? ''
			if (!errorMessage) {
				errorMessage = fetchResponseJson.errors.map((e: any) => e.message).join(', \n')
			}
			if (errorMessage == 'datos.formatos is not iterable') {
				return data as RESPONSE
			}
			throw new Error(errorMessage)
		}
		//console.log('data', data)
		return data as RESPONSE
	}
}
