<!-- eslint-disable vue/multi-word-component-names -->
<script lang="ts" setup>
/**
 * @name NavBar
 * @author @tirsomartinezreyes
 * @version 2.0.1
 * @description Componente que dibuja el menú de la aplicación
 * @changelog 2.0.1 - 30/sep/24 -  @tirsomartinezreyes - Se integran lista negra de rutas por entorno
 *
 * Responsabilidades
 *
 * ✅ Mostrar el menú de la aplicación organizada por grupos
 *
 */
import router, { IRouteWithMeta } from 'src/router'
import { ACL, ACLBlackList } from 'src/router/acl'
import { useUserStore } from 'src/store/auth'
import { useSessionStore } from 'src/store/session'
import { useConfigStore } from 'src/store/config'
import { EnvironmentType } from 'cofepris-typesafe/Constants/Config'
import { Roles } from 'cofepris-typesafe/Types/Roles'
import { computed } from 'vue'
import { RouterLink } from 'vue-router'
import { VaAvatar } from 'vuestic-ui'

const userStore = useUserStore()
const sessionMachine = useSessionStore().machine
const entornosDesarrollo: EnvironmentType[] = [EnvironmentType.DEV, EnvironmentType.STAGE]

interface INavBarItem {
	label: string
	to: string
	class?: string
	children?: INavBarItem[]
	iconLeft?: string
	iconRight?: string
}

const recortaNombreGrupo = function (nombre: string) {
	let response = ''
	let postfix = '...'
	let availableCharacters = 40
	if (nombre.length < availableCharacters) {
		response = nombre
	} else {
		response = nombre.substring(0, availableCharacters - postfix.length) + postfix
	}
	return response
}

const items = computed<INavBarItem[]>(() => {
	let elements: INavBarItem[] = []
	const originalRoutes: IRouteWithMeta[] = router.getRoutes() as unknown as IRouteWithMeta[]
	const newRoutes: IRouteWithMeta[] = []
	for (const originalRoute of originalRoutes) {
		if (originalRoute.meta.group === null) {
			continue
		}

		if (ACLBlackList[originalRoute.path] && ACLBlackList[originalRoute.path].includes(useConfigStore().getEnvironment())) {
			continue
		}

		const route = {
			path: originalRoute.path,
			name: originalRoute.name,
			component: originalRoute.component,
			meta: originalRoute.meta,
			children: originalRoute.children,
			iconLeft: originalRoute.meta.iconLeft,
			iconRight: originalRoute.meta.iconRight
		}

		if (route.meta.group === 'Configuracion' && route.name !== 'Modo de Operación') {
			route.meta.isVisible = entornosDesarrollo.includes(useConfigStore().getEnvironment())
		}
		newRoutes.push(route)
		let requireRoles = route.meta.requiresRoles === undefined ? originalRoute.meta.requiresRoles : route.meta.requiresRoles
		if (requireRoles.indexOf(userStore.User.activeRole.type) >= 0) {
			if (route.meta.isVisible === false) {
				continue
			} else {
				if (route.path.includes(':RFC')) {
					route.path = route.path.replace(':RFC', userStore.User.activeGroup.grupo)
				}
				if (route.name === undefined) {
					continue
				}
				if (route.meta.group === undefined) {
					elements.push({
						label: route.meta.customLabel || (route.name?.toString() ?? ''),
						to: route.path,
						class: 'routerLink ' + route.meta.cssClass || '',
						children: undefined,
						iconLeft: route.meta.iconLeft,
						iconRight: route.meta.iconRight
					})
					continue
				}

				if (route.meta.useRFC === true) {
					route.meta.group = recortaNombreGrupo(userStore.User.activeGroup.solicitante)
				}

				let element: INavBarItem | undefined = elements.find(element => element.label === route.meta.group)

				if (element == undefined) {
					element = {
						label: route.meta.customLabel || (route.meta.group as string),
						to: '',
						class: 'routerLink ' + route.meta.cssClass || '',
						children: [],
						iconLeft: route.meta.iconLeft,
						iconRight: route.meta.iconRight
					}
					elements.push(element)
				}
				element.children!.push({
					label: route.meta.customLabel || (route.name?.toString() ?? ''),
					to: route.path,
					class: 'routerLink ' + route.meta.cssClass || '',
					children: undefined,
					iconLeft: route.meta.iconLeft,
					iconRight: route.meta.iconRight
				})
			}
		}
	}
	return elements
})

const nombreUsuario = computed(() => {
	let response = ''
	let postfix = '...'
	let availableCharacters = 15
	let piezas = userStore.User.identityToken.data.nombre.split(' ')
	let nombre = ''

	if (piezas.length == 1) {
		nombre = piezas[0]
	}

	if (piezas.length >= 2) {
		nombre = piezas[0] + ' ' + piezas[1]
	}

	if (nombre.length < availableCharacters) {
		response = nombre
	} else {
		response = nombre.substring(0, availableCharacters - postfix.length) + postfix
	}

	return response
})

const getAvatarFontSize = function (code: string) {
	let response = '20px'
	if (code.length > 1) {
		return '16px'
	}
	return response
}

const doChangeRol = function () {
	sessionMachine.send('CHANGE_ROLE')
}

// eslint-disable-next-line @typescript-eslint/require-await
const doLogout = async function () {
	sessionMachine.send('REQUEST_LOGOUT')
}

const has_only_one_role = computed(() => {
	if (userStore.User.identityToken.data.grupos.length == 1 && userStore.User.identityToken.data.grupos[0].roles.length == 1) {
		return true
	} else {
		return false
	}
})

function getBaseUrl() {
	let url = window.location.pathname

	if (url.includes('portal')) {
		url = url.substring(0, url.indexOf('portal'))
	}

	for (const key in ACL) {
		if (url.endsWith(key)) {
			url = url.replace(key, '')
			break
		}
	}

	if (url.length > 0 && !url.startsWith('/')) {
		url = '/' + url
	}

	if (url.endsWith('/')) {
		url = url.slice(0, -1)
	}

	const activeRoleType = userStore.User.activeRole.type
	const activeGroup = userStore.User.activeGroup.grupo

	switch (activeRoleType) {
		case Roles.ROL_ADMINISTRADOR.type:
			return `${url}/administrador/bitacoras`
		case Roles.ROL_AUTORIDAD.type:
		case Roles.ROL_COORDINADOR.type:
		case Roles.ROL_DICTAMINADOR.type:
			return `${url}/sistema/reportes`
		case Roles.ROL_SOLICITANTE_TITULAR_PERSONA_FISICA.type:
		case Roles.ROL_SOLICITANTE_AUTORIZADOR.type:
		case Roles.ROL_SOLICITANTE_EDITOR.type:
		case Roles.ROL_SOLICITANTE_VISOR.type:
			return `${url}/portal/GRUPO/${activeGroup}`
		case Roles.ROL_ANONIMO.type:
			return `${url}/`
		case Roles.ROL_SUPERADMINISTRADOR.type:
			return `${url}/superadministrador`
		default:
			return `${url}/`
	}
}
</script>

<template>
	<nav class="navbar navbar-inverse sub-navbar navbar-fixed-top">
		<div class="container">
			<div class="navbar-header">
				<a class="navbar-brand" :href="getBaseUrl()">
					{{ useConfigStore().getConfig().GENERAL.SYSTEM_NAME }}
				</a>
				<div style="position: absolute; bottom: 5px; left: 70px; overflow: hidden"><EnvironmentSelector :debug="false" /></div>
			</div>
			<div class="collapse navbar-collapse" style="padding: auto">
				<!--Seccion de usuario autenticado-->
				<ul v-if="userStore.User.activeRole.type != Roles.ROL_ANONIMO.type" class="nav navbar-nav navbar-right">
					<li class="dropdown">
						<a cyid="RouterLink_NavUserItem" href="#" class="dropdown-toggle" data-toggle="dropdown">
							<va-avatar :color="useConfigStore().getConfig().COLORS.ROLE_AVATAR_BADGE_ACTIVE" size="30px" :font-size="getAvatarFontSize(userStore.User.activeRole.code)">{{
								userStore.User.activeRole.code
							}}</va-avatar>
							{{ nombreUsuario }}
							<b class="caret"></b>
						</a>
						<ul class="dropdown-menu" style="padding: 10px">
							<li v-if="!has_only_one_role">
								<a cyid="RouterLink_NavCambiarRolItem" href="#" @click="doChangeRol">Cambiar de Rol</a>
								<div class="roleLabel">
									{{ userStore.User.activeRole.label }}
								</div>
							</li>
							<li v-if="!has_only_one_role" class="divider"></li>
							<li>
								<RouterLink class="routerLink" to="/perfil">Mi cuenta</RouterLink>
							</li>
							<li class="divider"></li>

							<li v-if="entornosDesarrollo.includes(useConfigStore().getEnvironment())" class="debug">
								<RouterLink class="routerLink" to="/perfil/sesion">Sesión</RouterLink>
							</li>

							<li class="divider"></li>
							<li>
								<button class="btn btn-primary" type="button" @click="doLogout">Cerrar Sesión</button>
							</li>
						</ul>
					</li>
				</ul>

				<!-- COMIENZA BARRA MENÚ-->
				<ul v-for="item in items" :key="item.label" class="nav navbar-nav navbar-right">
					<!--Elementos de la barra de menú con hijos-->
					<li v-if="item.children" class="dropdown bold">
						<a href="#" class="dropdown-toggle" data-toggle="dropdown">
							<span v-if="item.iconLeft" :class="item.iconLeft" aria-hidden="true"></span>
							{{ item.label }} <b class="caret"></b
						></a>
						<ul class="dropdown-menu pt0 pb0">
							<!--Elementos hijos-->
							<li v-for="subitem in item.children" :key="subitem.label" class="mt5 mb5">
								<RouterLink :cyid="'RouterLink_' + subitem.label" :class="subitem.class" :to="subitem.to">
									<span v-if="subitem.iconLeft" :class="subitem.iconLeft" aria-hidden="true"></span>
									{{ subitem.label }}
									<span v-if="subitem.iconRight" :class="subitem.iconRight" aria-hidden="true"></span>
								</RouterLink>
							</li>
						</ul>
					</li>

					<!--Elementos de la barra de menú sin hijos-->
					<li v-if="!item.children" class="">
						<RouterLink :cyid="'RouterLink_' + item.label" v-if="!item.children" :class="item.class" :to="item.to">
							<span v-if="item.iconLeft" :class="item.iconLeft" aria-hidden="true"></span>
							{{ item.label }}
							<span v-if="item.iconRight" :class="item.iconRight" aria-hidden="true"></span>
						</RouterLink>
					</li>
				</ul>
			</div>
		</div>
		<div v-if="userStore.User.activeRole.type != Roles.ROL_ANONIMO.type" class="container gobmx-bg-gray-1_5 pt5 bbw1 bss bclg">
			<BreadCrumb class="" />
		</div>
	</nav>
</template>

<style scoped>
.roleLabel {
	font-size: xx-small;
	color: black;
	text-align: center;
}

.navbar-right {
	margin-right: 20px;
}
.floatCountdown {
	background-color: #ddc9a3;
	display: inline-block;
	position: fixed;
	text-align: center;
	top: 150px;
	right: 50px;
	z-index: 1000;
	border-radius: 100px;
	width: 170px;
	height: 70px;
}
.floatDivSesion {
	display: inline-block;
	position: fixed;
	text-align: center;
	top: 222px;
	right: 50px;
	z-index: 1000;
	width: 170px;
	height: auto;
}
.countDown {
	font-weight: bold;
	color: white;
	float: right;
}
</style>
