import { EFirmaDelimitador } from 'cofepris-typesafe/Modules/Firma'

export enum EnvironmentType {
	DEV = 'DEV',
	LUIS = 'LUIS',
	SANTIAGO = 'SANTIAGO',
	STAGE = 'STAGE',
	SEC = 'SEC',
	QA = 'QA',
	// DigiprisRegulacionenLinea es producción, cuidado con mover esta sección por que se tendría que desplegar toda la infraestructura y quizás se perderán los registros NS de route53
	DRL = 'DRL'
}

export interface IEnviromentConfig {
	AUTHENTICATION: {
		ORGANIZATION_GROUP: string
		PUBLIC_GROUP: string
		RPID: string
		JWT_ISSUER: string
		JWK_PUBLIC: string
		JWT_ACCESS_TOKEN_REMAINING_SECONDS_RENEWAL: number
		WEBAUTHN_USERNAME_POSTFIX: string
	}
	SESSION: {
		MAX_IDLE_TIME_SECONDS: number
		ALERT_IDLE_TIME_SECONDS: number
	}
	API_SERVICES: {
		CONFIG: {
			GET: string
			SET: string
		}
		AUTHENTICATION: {
			LOGOUT: string
			UPDATE_ME: string
			RSA_GET_LOGIN_MESSAGE: string
			RSA_GET_IDENTITY_TOKEN: string
			RSA_GET_ACCESS_TOKEN: string
			WEBAUTHN_GET_AUTHENTICATOR_MESSAGE: string
			WEBAUTHN_AUTHENTICATOR_ACTIVATE: string
			WEBAUTHN_AUTHENTICATOR_LOGIN: string
		}
		PAGOS: {
			REGISTRAR_LLAVES: string
			CONSULTAR_LLAVES: string
		}
		GET_PUBLIC_DOCUMENT: string
		URL_PUBLIC_DOCUMENT: string
		ORGANIZATION: string
		USERS_BY_ORGANIZATIVE_UNIT: string
		GET_USERS_BY_UUIDS: string
		LOGS: string
		GRAPQLH: {
			URL: string
		}
		RNEC: {
			BUSCAR: string
			OBTENER_ENSAYO: string
		}
	}
	AWS_CAPTCHA: {
		API_KEY: string
		URL_SCRIPT: string
	}
}

export interface configType {
	[x: string]: string | undefined | any
	COLORS: {
		GENERAL: {
			RED: string
			BLUE: string
		}
		ROLE_AVATAR_BADGE_ACTIVE: string
		ROLE_AVATAR_BADGE: string
		ROLES: {
			SUPERADMINISTRADOR: string
			ADMINISTRADOR: string
			AUTORIDAD: string
			COORDINADOR: string
			DICTAMINADOR: string
			SOLICITANTE_TITULAR_PERSONA_MORAL: string
			SOLICITANTE_TITULAR_PERSONA_FISICA: string
			SOLICITANTE_AUTORIZADOR: string
			SOLICITANTE_EDITOR: string
			SOLICITANTE_VISOR: string
			ANONIMO: string
		}
	}
	ENVIRONMENT: EnvironmentType
	GENERAL: {
		ORGANIZATION_NAME: string
		ORGANIZATION_SHORT_NAME: string
		SYSTEM_NAME: string
		DELIMITADOR_VALOR_VACIO: string
		MAX_FETCH_TIMEOUT: number
		FAKER_INDEX: number
	}
	DISCLAIMERS: {
		SOLICITUD: string
	}
	ENVIRONMENTS: {
		[EnvironmentType.DEV]: IEnviromentConfig
		[EnvironmentType.LUIS]: IEnviromentConfig
		[EnvironmentType.SANTIAGO]: IEnviromentConfig
		[EnvironmentType.SEC]: IEnviromentConfig
		[EnvironmentType.QA]: IEnviromentConfig
		[EnvironmentType.STAGE]: IEnviromentConfig
		[EnvironmentType.DRL]: IEnviromentConfig
	}
}
export const config: configType = {
	COLORS: {
		GENERAL: {
			RED: '#9d2449',
			BLUE: '#218ADC'
		},
		ROLE_AVATAR_BADGE_ACTIVE: '#9d2449',
		ROLE_AVATAR_BADGE: '#545454',
		ROLES: {
			SUPERADMINISTRADOR: '#900C3F',
			ADMINISTRADOR: '#900C3F',
			AUTORIDAD: '#900C3F',
			COORDINADOR: '#36900C',
			DICTAMINADOR: '#36900C',
			SOLICITANTE_TITULAR_PERSONA_MORAL: '#218ADC',
			SOLICITANTE_TITULAR_PERSONA_FISICA: '#218ADC',
			SOLICITANTE_AUTORIZADOR: '#218ADC',
			SOLICITANTE_EDITOR: '#218ADC',
			SOLICITANTE_VISOR: '#218ADC',
			ANONIMO: '#00000'
		}
	},
	ENVIRONMENT: EnvironmentType.DEV,
	GENERAL: {
		ORGANIZATION_NAME: 'COMISION FEDERAL PARA LA PROTECCION CONTRA RIESGOS SANITARIOS',
		ORGANIZATION_SHORT_NAME: 'COFEPRIS',
		SYSTEM_NAME: 'DIGIPRIS: Regulación en Línea',
		DELIMITADOR_VALOR_VACIO: EFirmaDelimitador.DELIMITADOR_VALOR_VACIO,
		MAX_FETCH_TIMEOUT: 60 * 31 * 1000, // 31 min
		FAKER_INDEX: 0 //Permite numerar los campos que son llenados por el autopolulate para mejor identificación por parte del equipo de testing
	},
	DISCLAIMERS: {
		SOLICITUD:
			'Declaro bajo protesta decir verdad que cumplo con los requisitos y normatividad aplicable, sin que me eximan de que la autoridad sanitaria verifique su cumplimiento, esto sin perjuicio de las sanciones en que puedo incurrir por falsedad de declaraciones dadas a una autoridad. Y acepto que las notificaciónes de este trámite se realicen a través de medios electrónicos de acuerdo a los términos y condiciones de la plataforma.(Artículo 35 fracción II de la Ley Federal de Procedimiento Administrativo).Estoy de acuerdo con que datos o documentos anexos a la solicitud pueden contener información confidencial y que podría hacerse pública.He leído y estoy de acuerdo con los términos y condiciones del uso de la plataforma'
	},
	ENVIRONMENTS: {
		DEV: {
			AUTHENTICATION: {
				ORGANIZATION_GROUP: 'ESIMIOS',
				PUBLIC_GROUP: 'PUBLICO',
				RPID: 'TBD',
				JWT_ISSUER: 'esimios-sso',
				JWK_PUBLIC: `-----BEGIN PUBLIC KEY-----
                MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEAxDS5WPALmWV3gf52hCK4
                W+whXEW9z33knEcphYi8nDODPOI4/C21e3K3M2DIF4cefFRVQsHTfuG2sNVVEhBf
                TF3jKmKSuOC94+L3M3N9m9XmLMtCtJ83aJzJ4Nx0PZyFqLXDOaFcJIT9jh2dqaQy
                Deu/WRr4z1MtE2IipcrzkzDzQeDhOHMj0AZw8pACzgoKSj9Z0mMj+YGiWiG86lHH
                H61k6xeNUqs8y4pEJj+uE15DFMRueX8jFsV7TZ8OnczmpCZaWdsy8hvSdZyXmMg4
                X5NAXj5Jzqwzror+NfChsb+Ea3LgjxxwOHD7m3bLA7kOFF98d0WrNBtlKxku2waY
                GqkcUnDsaV6Q5bE916oVmGaP1SBMxg/qB+xE0oV7MhPHgJ0sNa/QSRy4JSTZK1Tw
                SYgZcrUazyhdiSUjvw3wCn87AIFFO7x6LdYbfsC+9UKoi+q/pYwRvfoxO5OHLcGY
                mLmUaH2MmtjPWnTouyuh6FajsxBQCAWtCrba5Lkwks+vju4YuTJS6f7Xk+RW1wKg
                2O8uDIHnF3twcfbUmCUGMqaJHQjHHAdqkTkR6DvGOiu8k0CJplJLVPdh1cj9+ens
                Mdjl5m976DC37uaDmRb0CkQLOhmpFEsxBINwbPnxFFxm5pbX85zz56xbNwXR+QKr
                9Em6O4zOWMmsGH23zSUySW0CAwEAAQ==
                -----END PUBLIC KEY-----`,
				JWT_ACCESS_TOKEN_REMAINING_SECONDS_RENEWAL: 60 * 2,
				WEBAUTHN_USERNAME_POSTFIX: '-DEV'
			},
			SESSION: {
				MAX_IDLE_TIME_SECONDS: 60 * 15,
				ALERT_IDLE_TIME_SECONDS: 60 * 3
			},
			API_SERVICES: {
				GET_PUBLIC_DOCUMENT: 'https://pvlstiobvg2v6dezrm6zaurr3q0utcfs.lambda-url.us-east-1.on.aws/',
				URL_PUBLIC_DOCUMENT: 'https://dev.cofepris.esime.org/sitio/validacion_documentos/',
				CONFIG: {
					GET: 'https://jyzu36t110.execute-api.us-east-1.amazonaws.com/dev/getConfig',
					SET: 'https://jyzu36t110.execute-api.us-east-1.amazonaws.com/dev/config'
				},
				AUTHENTICATION: {
					UPDATE_ME: 'https://jyzu36t110.execute-api.us-east-1.amazonaws.com/dev/updateMe',
					LOGOUT: 'https://jyzu36t110.execute-api.us-east-1.amazonaws.com/dev/logout',
					RSA_GET_LOGIN_MESSAGE: 'https://jyzu36t110.execute-api.us-east-1.amazonaws.com/dev/requestAccess',
					RSA_GET_IDENTITY_TOKEN: 'https://jyzu36t110.execute-api.us-east-1.amazonaws.com/dev/requestLogin',
					RSA_GET_ACCESS_TOKEN: 'https://jyzu36t110.execute-api.us-east-1.amazonaws.com/dev/requestAccessToken',
					WEBAUTHN_GET_AUTHENTICATOR_MESSAGE: 'https://jyzu36t110.execute-api.us-east-1.amazonaws.com/dev/registerAuthenticator',
					WEBAUTHN_AUTHENTICATOR_ACTIVATE: 'https://jyzu36t110.execute-api.us-east-1.amazonaws.com/dev/activateAuthenticator',
					WEBAUTHN_AUTHENTICATOR_LOGIN: 'https://jyzu36t110.execute-api.us-east-1.amazonaws.com/dev/requestFidoLogin'
				},
				PAGOS: {
					REGISTRAR_LLAVES: 'https://ymc2c4n6xc.execute-api.us-east-1.amazonaws.com/dev/registrarLlavesPago',
					CONSULTAR_LLAVES: 'https://ymc2c4n6xc.execute-api.us-east-1.amazonaws.com/dev/obtenerLlavesPago'
				},
				ORGANIZATION: 'https://jyzu36t110.execute-api.us-east-1.amazonaws.com/dev/users',
				USERS_BY_ORGANIZATIVE_UNIT: 'https://jyzu36t110.execute-api.us-east-1.amazonaws.com/dev/getUsersFromUnidad',
				GET_USERS_BY_UUIDS: 'https://jyzu36t110.execute-api.us-east-1.amazonaws.com/dev/getUsersByUuids',
				LOGS: 'https://jyzu36t110.execute-api.us-east-1.amazonaws.com/dev/getBinnacle',
				GRAPQLH: {
					URL: 'https://6k7xokvr7fbc7on6xxiwlhy6am.appsync-api.us-east-1.amazonaws.com/graphql'
				},
				RNEC: {
					BUSCAR: 'https://lyrwawhtr27hfjubxrlxq2goji0ohuaj.lambda-url.us-east-1.on.aws/',
					OBTENER_ENSAYO: 'https://eqvonzmb2gxzhftwi4wjjsm45i0eauna.lambda-url.us-east-1.on.aws/'
				}
			},
			AWS_CAPTCHA: {
				API_KEY:
					'vVqBZMB62GKKuw9wPMj18nDAecWdokN9+At8gs5Q+DQHI1mBS/FC2hTncLJFx6LSwppdm9Wu5TMT56/2RqIqjieu9oG0iTo8w3vXrS5hneFRIiQJ1RgjUz8X0pEtrAeS4vp3KKzWLek0Rl3qEeJk8QOSOhp9VFh0n9+IhRHRWrOwWDMn3QkR/gjWeIzg8kwUql2Cf3w/dF9khPlXYQzsNs1czPIYkMrW0JaQJUXh6hxiV/ivGqCGNSNGV4UgDc+tBiiJH2PH3aCTge3hIBIkPIso3auxNxKMaQYCtvBlNIOIWcMflofrs9HHvqOZnCjfl64Dbo2jjGYemAceRSsh+zLaE0ACtcI3L4/dNQVTEJXPcSbOuxWmSl6+2heWeMs3oWXngsWX5uIghNfwDerzqSaXOpdeTvPycbcgNGaDE8qH9fpCerHh6ALLToo+a5ejyO6A2E5p4169zjks13uOWSAyf1MqY/1P7KFAZ+QdL/gCq5nP2+2lWSosxo8eUegGszPVFlUO5wNlkUreRbDRfKC7Jna5WzI4tmnCw38/iyLnKms3+KzuVORhb8D3aDHO6pnp5JdOgu91h9i8JOtlg17MTagVZHNcvijIaAkIlE9O7fQ6VPbB5tkpez8lteiI2g0pJeb9NXQ2Puar4taVXaR76J2QO6GaQ2TTNaFFsbI=_1_1',
				URL_SCRIPT: 'https://d9fe19466ef2.edge.captcha-sdk.awswaf.com/d9fe19466ef2/jsapi.js'
			}
		},
		LUIS: {
			AUTHENTICATION: {
				ORGANIZATION_GROUP: 'ESIMIOS',
				PUBLIC_GROUP: 'PUBLICO',
				RPID: 'TBD',
				JWT_ISSUER: 'esimios-sso',
				JWK_PUBLIC: `-----BEGIN PUBLIC KEY-----
                MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEAxDS5WPALmWV3gf52hCK4
                W+whXEW9z33knEcphYi8nDODPOI4/C21e3K3M2DIF4cefFRVQsHTfuG2sNVVEhBf
                TF3jKmKSuOC94+L3M3N9m9XmLMtCtJ83aJzJ4Nx0PZyFqLXDOaFcJIT9jh2dqaQy
                Deu/WRr4z1MtE2IipcrzkzDzQeDhOHMj0AZw8pACzgoKSj9Z0mMj+YGiWiG86lHH
                H61k6xeNUqs8y4pEJj+uE15DFMRueX8jFsV7TZ8OnczmpCZaWdsy8hvSdZyXmMg4
                X5NAXj5Jzqwzror+NfChsb+Ea3LgjxxwOHD7m3bLA7kOFF98d0WrNBtlKxku2waY
                GqkcUnDsaV6Q5bE916oVmGaP1SBMxg/qB+xE0oV7MhPHgJ0sNa/QSRy4JSTZK1Tw
                SYgZcrUazyhdiSUjvw3wCn87AIFFO7x6LdYbfsC+9UKoi+q/pYwRvfoxO5OHLcGY
                mLmUaH2MmtjPWnTouyuh6FajsxBQCAWtCrba5Lkwks+vju4YuTJS6f7Xk+RW1wKg
                2O8uDIHnF3twcfbUmCUGMqaJHQjHHAdqkTkR6DvGOiu8k0CJplJLVPdh1cj9+ens
                Mdjl5m976DC37uaDmRb0CkQLOhmpFEsxBINwbPnxFFxm5pbX85zz56xbNwXR+QKr
                9Em6O4zOWMmsGH23zSUySW0CAwEAAQ==
                -----END PUBLIC KEY-----`,
				JWT_ACCESS_TOKEN_REMAINING_SECONDS_RENEWAL: 60 * 2,
				WEBAUTHN_USERNAME_POSTFIX: '-DEV'
			},
			SESSION: {
				MAX_IDLE_TIME_SECONDS: 60 * 15,
				ALERT_IDLE_TIME_SECONDS: 60 * 3
			},
			API_SERVICES: {
				GET_PUBLIC_DOCUMENT: 'https://qz6zndb5udaqvljtcsdpqq5rhm0wycxe.lambda-url.us-east-1.on.aws/',
				URL_PUBLIC_DOCUMENT: 'https://dev.cofepris.esime.org/sitio/validacion_documentos/',
				CONFIG: {
					GET: 'https://jyzu36t110.execute-api.us-east-1.amazonaws.com/dev/getConfig',
					SET: 'https://jyzu36t110.execute-api.us-east-1.amazonaws.com/dev/config'
				},
				AUTHENTICATION: {
					UPDATE_ME: 'https://jyzu36t110.execute-api.us-east-1.amazonaws.com/dev/updateMe',
					LOGOUT: 'https://jyzu36t110.execute-api.us-east-1.amazonaws.com/dev/logout',
					RSA_GET_LOGIN_MESSAGE: 'https://jyzu36t110.execute-api.us-east-1.amazonaws.com/dev/requestAccess',
					RSA_GET_IDENTITY_TOKEN: 'https://jyzu36t110.execute-api.us-east-1.amazonaws.com/dev/requestLogin',
					RSA_GET_ACCESS_TOKEN: 'https://jyzu36t110.execute-api.us-east-1.amazonaws.com/dev/requestAccessToken',
					WEBAUTHN_GET_AUTHENTICATOR_MESSAGE: 'https://jyzu36t110.execute-api.us-east-1.amazonaws.com/dev/registerAuthenticator',
					WEBAUTHN_AUTHENTICATOR_ACTIVATE: 'https://jyzu36t110.execute-api.us-east-1.amazonaws.com/dev/activateAuthenticator',
					WEBAUTHN_AUTHENTICATOR_LOGIN: 'https://jyzu36t110.execute-api.us-east-1.amazonaws.com/dev/requestFidoLogin'
				},
				PAGOS: {
					REGISTRAR_LLAVES: '...',
					CONSULTAR_LLAVES: '...'
				},
				ORGANIZATION: 'https://jyzu36t110.execute-api.us-east-1.amazonaws.com/dev/users',
				USERS_BY_ORGANIZATIVE_UNIT: 'https://jyzu36t110.execute-api.us-east-1.amazonaws.com/dev/getUsersFromUnidad',
				GET_USERS_BY_UUIDS: 'https://jyzu36t110.execute-api.us-east-1.amazonaws.com/dev/getUsersByUuids',
				LOGS: 'https://jyzu36t110.execute-api.us-east-1.amazonaws.com/dev/getBinnacle',
				GRAPQLH: {
					URL: 'https://mktnchqg6vaf7dl5av2wurqklq.appsync-api.us-east-1.amazonaws.com/graphql'
				},
				RNEC: {
					BUSCAR: 'https://kosx6ozybv5hvagn2xfkuns27u0aatyq.lambda-url.us-east-1.on.aws/',
					OBTENER_ENSAYO: 'https://6gznpzxteiemhqvmqm2kywy6ci0pwwia.lambda-url.us-east-1.on.aws/'
				}
			},
			AWS_CAPTCHA: {
				API_KEY:
					'vVqBZMB62GKKuw9wPMj18nDAecWdokN9+At8gs5Q+DQHI1mBS/FC2hTncLJFx6LSwppdm9Wu5TMT56/2RqIqjieu9oG0iTo8w3vXrS5hneFRIiQJ1RgjUz8X0pEtrAeS4vp3KKzWLek0Rl3qEeJk8QOSOhp9VFh0n9+IhRHRWrOwWDMn3QkR/gjWeIzg8kwUql2Cf3w/dF9khPlXYQzsNs1czPIYkMrW0JaQJUXh6hxiV/ivGqCGNSNGV4UgDc+tBiiJH2PH3aCTge3hIBIkPIso3auxNxKMaQYCtvBlNIOIWcMflofrs9HHvqOZnCjfl64Dbo2jjGYemAceRSsh+zLaE0ACtcI3L4/dNQVTEJXPcSbOuxWmSl6+2heWeMs3oWXngsWX5uIghNfwDerzqSaXOpdeTvPycbcgNGaDE8qH9fpCerHh6ALLToo+a5ejyO6A2E5p4169zjks13uOWSAyf1MqY/1P7KFAZ+QdL/gCq5nP2+2lWSosxo8eUegGszPVFlUO5wNlkUreRbDRfKC7Jna5WzI4tmnCw38/iyLnKms3+KzuVORhb8D3aDHO6pnp5JdOgu91h9i8JOtlg17MTagVZHNcvijIaAkIlE9O7fQ6VPbB5tkpez8lteiI2g0pJeb9NXQ2Puar4taVXaR76J2QO6GaQ2TTNaFFsbI=_1_1',
				URL_SCRIPT: 'https://d9fe19466ef2.edge.captcha-sdk.awswaf.com/d9fe19466ef2/jsapi.js'
			}
		},
		SANTIAGO: {
			AUTHENTICATION: {
				ORGANIZATION_GROUP: 'ESIMIOS',
				PUBLIC_GROUP: 'PUBLICO',
				RPID: 'TBD',
				JWT_ISSUER: 'esimios-sso',
				JWK_PUBLIC: `-----BEGIN PUBLIC KEY-----
                MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEAxDS5WPALmWV3gf52hCK4
                W+whXEW9z33knEcphYi8nDODPOI4/C21e3K3M2DIF4cefFRVQsHTfuG2sNVVEhBf
                TF3jKmKSuOC94+L3M3N9m9XmLMtCtJ83aJzJ4Nx0PZyFqLXDOaFcJIT9jh2dqaQy
                Deu/WRr4z1MtE2IipcrzkzDzQeDhOHMj0AZw8pACzgoKSj9Z0mMj+YGiWiG86lHH
                H61k6xeNUqs8y4pEJj+uE15DFMRueX8jFsV7TZ8OnczmpCZaWdsy8hvSdZyXmMg4
                X5NAXj5Jzqwzror+NfChsb+Ea3LgjxxwOHD7m3bLA7kOFF98d0WrNBtlKxku2waY
                GqkcUnDsaV6Q5bE916oVmGaP1SBMxg/qB+xE0oV7MhPHgJ0sNa/QSRy4JSTZK1Tw
                SYgZcrUazyhdiSUjvw3wCn87AIFFO7x6LdYbfsC+9UKoi+q/pYwRvfoxO5OHLcGY
                mLmUaH2MmtjPWnTouyuh6FajsxBQCAWtCrba5Lkwks+vju4YuTJS6f7Xk+RW1wKg
                2O8uDIHnF3twcfbUmCUGMqaJHQjHHAdqkTkR6DvGOiu8k0CJplJLVPdh1cj9+ens
                Mdjl5m976DC37uaDmRb0CkQLOhmpFEsxBINwbPnxFFxm5pbX85zz56xbNwXR+QKr
                9Em6O4zOWMmsGH23zSUySW0CAwEAAQ==
                -----END PUBLIC KEY-----`,
				JWT_ACCESS_TOKEN_REMAINING_SECONDS_RENEWAL: 60 * 2,
				WEBAUTHN_USERNAME_POSTFIX: '-DEV'
			},
			SESSION: {
				MAX_IDLE_TIME_SECONDS: 60 * 15,
				ALERT_IDLE_TIME_SECONDS: 60 * 3
			},
			API_SERVICES: {
				GET_PUBLIC_DOCUMENT: 'https://7afh6ot4ypsa2rb375gstd7ycu0dgwna.lambda-url.us-east-1.on.aws/',
				URL_PUBLIC_DOCUMENT: 'https://dev.cofepris.esime.org/sitio/validacion_documentos/',
				CONFIG: {
					GET: 'https://jyzu36t110.execute-api.us-east-1.amazonaws.com/dev/getConfig',
					SET: 'https://jyzu36t110.execute-api.us-east-1.amazonaws.com/dev/config'
				},
				AUTHENTICATION: {
					UPDATE_ME: 'https://jyzu36t110.execute-api.us-east-1.amazonaws.com/dev/updateMe',
					LOGOUT: 'https://jyzu36t110.execute-api.us-east-1.amazonaws.com/dev/logout',
					RSA_GET_LOGIN_MESSAGE: 'https://jyzu36t110.execute-api.us-east-1.amazonaws.com/dev/requestAccess',
					RSA_GET_IDENTITY_TOKEN: 'https://jyzu36t110.execute-api.us-east-1.amazonaws.com/dev/requestLogin',
					RSA_GET_ACCESS_TOKEN: 'https://jyzu36t110.execute-api.us-east-1.amazonaws.com/dev/requestAccessToken',
					WEBAUTHN_GET_AUTHENTICATOR_MESSAGE: 'https://jyzu36t110.execute-api.us-east-1.amazonaws.com/dev/registerAuthenticator',
					WEBAUTHN_AUTHENTICATOR_ACTIVATE: 'https://jyzu36t110.execute-api.us-east-1.amazonaws.com/dev/activateAuthenticator',
					WEBAUTHN_AUTHENTICATOR_LOGIN: 'https://jyzu36t110.execute-api.us-east-1.amazonaws.com/dev/requestFidoLogin'
				},
				PAGOS: {
					REGISTRAR_LLAVES: '...',
					CONSULTAR_LLAVES: '...'
				},
				ORGANIZATION: 'https://jyzu36t110.execute-api.us-east-1.amazonaws.com/dev/users',
				USERS_BY_ORGANIZATIVE_UNIT: 'https://jyzu36t110.execute-api.us-east-1.amazonaws.com/dev/getUsersFromUnidad',
				GET_USERS_BY_UUIDS: 'https://jyzu36t110.execute-api.us-east-1.amazonaws.com/dev/getUsersByUuids',
				LOGS: 'https://jyzu36t110.execute-api.us-east-1.amazonaws.com/dev/getBinnacle',
				GRAPQLH: {
					URL: 'https://poumwzj7p5dgdggtkybh64eyja.appsync-api.us-east-1.amazonaws.com/graphql'
				},
				RNEC: {
					BUSCAR: 'https://otpvamun3lymboq6pthgkyxnxu0gfvfs.lambda-url.us-east-1.on.aws/',
					OBTENER_ENSAYO: 'https://joq7n255fxs2dw2ehvfsw3ewfa0tbcqa.lambda-url.us-east-1.on.aws/'
				}
			},
			AWS_CAPTCHA: {
				API_KEY:
					'vVqBZMB62GKKuw9wPMj18nDAecWdokN9+At8gs5Q+DQHI1mBS/FC2hTncLJFx6LSwppdm9Wu5TMT56/2RqIqjieu9oG0iTo8w3vXrS5hneFRIiQJ1RgjUz8X0pEtrAeS4vp3KKzWLek0Rl3qEeJk8QOSOhp9VFh0n9+IhRHRWrOwWDMn3QkR/gjWeIzg8kwUql2Cf3w/dF9khPlXYQzsNs1czPIYkMrW0JaQJUXh6hxiV/ivGqCGNSNGV4UgDc+tBiiJH2PH3aCTge3hIBIkPIso3auxNxKMaQYCtvBlNIOIWcMflofrs9HHvqOZnCjfl64Dbo2jjGYemAceRSsh+zLaE0ACtcI3L4/dNQVTEJXPcSbOuxWmSl6+2heWeMs3oWXngsWX5uIghNfwDerzqSaXOpdeTvPycbcgNGaDE8qH9fpCerHh6ALLToo+a5ejyO6A2E5p4169zjks13uOWSAyf1MqY/1P7KFAZ+QdL/gCq5nP2+2lWSosxo8eUegGszPVFlUO5wNlkUreRbDRfKC7Jna5WzI4tmnCw38/iyLnKms3+KzuVORhb8D3aDHO6pnp5JdOgu91h9i8JOtlg17MTagVZHNcvijIaAkIlE9O7fQ6VPbB5tkpez8lteiI2g0pJeb9NXQ2Puar4taVXaR76J2QO6GaQ2TTNaFFsbI=_1_1',
				URL_SCRIPT: 'https://d9fe19466ef2.edge.captcha-sdk.awswaf.com/d9fe19466ef2/jsapi.js'
			}
		},
		SEC: {
			AUTHENTICATION: {
				ORGANIZATION_GROUP: 'COFEPRIS-SEC',
				PUBLIC_GROUP: 'PUBLICO',
				RPID: 'TBD',
				JWT_ISSUER: 'cofepris-sec-sso',
				JWK_PUBLIC: `-----BEGIN PUBLIC KEY-----
                MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEAoAhXheVmimd0G/53zs4m
                Ba353DPWHPLuzqM2S0uM/lL/y1HaJ0PbTCvIaVJ8VSyHWXLTKdCHPb6sLAg7dELC
                QOanU1DOcRoCNF89XdXcXXHIQ4IYE3TYAUzJTetPHgv2900Wlyjvco/RHSBURK9C
                3qsj7kSfsLJG5/u5OHpRzVqb0LGE4RRBPf1lTGEcM/NBMRVI5w3jeu5RnKEulmy6
                eaROXUVefkANibhZ0G0E5gVxEi7HTPwld9Nd+W/0sLyilvVoneGAefZ/t6Hrt3Mc
                u2iJ1dHh5twJs1FbnLuBX3UdOgWa8Twnc9GrYmNfpI6/7fKtYWmzfYOQsIniFcst
                OxSyhrMpN6IDNt1Ry1MLafv3pr7/i3MZT+EHLfmDF3EfmJNIvufap2Tbv6mm57Wa
                kDeSgFoPzmU75D64h4SP8hTDMZjgmahXvkBcIyjgD0kh6yUwzHSEu49k1nL3Rhxk
                OxJAdQlm9ukt4Z6rNoJCOcQMiW5Vmr0GdKLz73Ii5vamLjL52noNw3F2UgW4X9md
                BAOMNBsZ8TImfOoJEgDY6VM5IluKE/YolkKp7DsNIIzbSIsDXRUw1URzwTLcdKCd
                1gaZHUJBhNcVljL78XpNXoCcjRyjqeoqniIZbjS9P1DxlSeC4V3Usxg+qhKlTDbk
                z2rdAhx2pubquIt/dvKPQp0CAwEAAQ==
                -----END PUBLIC KEY-----`,
				JWT_ACCESS_TOKEN_REMAINING_SECONDS_RENEWAL: 60 * 2,
				WEBAUTHN_USERNAME_POSTFIX: '-SEC'
			},
			SESSION: {
				MAX_IDLE_TIME_SECONDS: 60 * 15,
				ALERT_IDLE_TIME_SECONDS: 60 * 3
			},
			API_SERVICES: {
				GET_PUBLIC_DOCUMENT: '',
				URL_PUBLIC_DOCUMENT: '',
				CONFIG: {
					GET: 'https://fs21dc7yg0.execute-api.us-east-2.amazonaws.com/sec/getConfig',
					SET: 'https://fs21dc7yg0.execute-api.us-east-2.amazonaws.com/sec/config'
				},
				AUTHENTICATION: {
					LOGOUT: 'https://fs21dc7yg0.execute-api.us-east-2.amazonaws.com/sec/logout',
					UPDATE_ME: 'https://fs21dc7yg0.execute-api.us-east-2.amazonaws.com/sec/updateMe',
					RSA_GET_LOGIN_MESSAGE: 'https://fs21dc7yg0.execute-api.us-east-2.amazonaws.com/sec/requestAccess',
					RSA_GET_IDENTITY_TOKEN: 'https://fs21dc7yg0.execute-api.us-east-2.amazonaws.com/sec/requestLogin',
					RSA_GET_ACCESS_TOKEN: 'https://fs21dc7yg0.execute-api.us-east-2.amazonaws.com/sec/requestAccessToken',
					WEBAUTHN_GET_AUTHENTICATOR_MESSAGE: 'https://fs21dc7yg0.execute-api.us-east-2.amazonaws.com/sec/registerAuthenticator',
					WEBAUTHN_AUTHENTICATOR_ACTIVATE: 'https://fs21dc7yg0.execute-api.us-east-2.amazonaws.com/sec/activateAuthenticator',
					WEBAUTHN_AUTHENTICATOR_LOGIN: 'https://fs21dc7yg0.execute-api.us-east-2.amazonaws.com/sec/requestFidoLogin'
				},
				PAGOS: {
					REGISTRAR_LLAVES: '...',
					CONSULTAR_LLAVES: '...'
				},
				ORGANIZATION: 'https://fs21dc7yg0.execute-api.us-east-2.amazonaws.com/sec/users',
				USERS_BY_ORGANIZATIVE_UNIT: 'https://fs21dc7yg0.execute-api.us-east-2.amazonaws.com/sec/getUsersFromUnidad',
				GET_USERS_BY_UUIDS: 'https://fs21dc7yg0.execute-api.us-east-2.amazonaws.com/sec/getUsersByUuids',
				LOGS: 'https://fs21dc7yg0.execute-api.us-east-2.amazonaws.com/sec/getBinnacle',
				GRAPQLH: {
					URL: 'https://2az7hcathvbvhl2g4u4bplm2b4.appsync-api.us-east-2.amazonaws.com/graphql'
				},
				RNEC: {
					BUSCAR: 'https://75arpealvtfitstkl7mauxo6dy0rizbu.lambda-url.us-east-2.on.aws/',
					OBTENER_ENSAYO: 'https://plbkrg32pipcesle2g2mlv6fvq0xdkkp.lambda-url.us-east-2.on.aws/'
				}
			},
			AWS_CAPTCHA: {
				API_KEY: '',
				URL_SCRIPT: ''
			}
		},
		QA: {
			AUTHENTICATION: {
				ORGANIZATION_GROUP: 'COFEPRIS',
				PUBLIC_GROUP: 'PUBLICO',
				RPID: 'TBD',
				JWT_ISSUER: 'cofepris-sso',
				JWK_PUBLIC: `-----BEGIN PUBLIC KEY-----
                MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEAsaGUhUvs8gg9lNh86G1I
                7uPUEJq+9kCzZ4oIqu0NnvJTcevrs/FP6/GprmloWfjGeSQTYUIFVtumfa++g+5O
                f03MG/36gcoAXmJH1PWYpWYmR4Ndsa+F7T8+c4B4UgHfF/BJCTJur91CFISnSYib
                aFjXXk8YWjo9U3WAIGvf3+YUA4UO4wmBX8vqjCHDfczaTQZWVC3GJeFxcITEKxgm
                zzXVuu2nJjt53otzdecu39hevzZezQla6t6vUrcezng2ZqZKbto0dr89FM5zQOHC
                sHtYQhO9o+yG2o06Fg6h6yovv4TauMvz0NaEyc/AbJ7LasexuJK7U4OHQP036Am6
                nuNQaM3cEaQnrFQMux0V86D5ONxGPX71bT4H+WEEe8/weCb8GoblCELm/rbuzj2N
                kJGArMc+eJqqprJViPyur+VZYL3R6Dy4+yv2AKH7wIkAKmVgsZtKBzDWZv8jy/7G
                c6i/f7ifBBHzigBknlfbsMp4Z1M9e404u0X6lOu/zFUyJ87KsyZc+5ut74OX0lN+
                CpAWG/8ZokP+aEfm2nSOnL7Bdip2BNmRNEe1wPh6IanoLN7LC9Cvlwd4Ek2X5PRb
                yosHxGNXV+xsHtGL5GnQV3FJrxZGt2QmjLbseJbtPPC7/KeETTYA8VS3nC2Hv+pA
                y8iEiaE5X7+YXbesUlehlD8CAwEAAQ==
                -----END PUBLIC KEY-----`,
				JWT_ACCESS_TOKEN_REMAINING_SECONDS_RENEWAL: 60 * 2,
				WEBAUTHN_USERNAME_POSTFIX: '-QA'
			},
			SESSION: {
				MAX_IDLE_TIME_SECONDS: 60 * 15,
				ALERT_IDLE_TIME_SECONDS: 60 * 3
			},
			API_SERVICES: {
				GET_PUBLIC_DOCUMENT: 'https://7kjufk3ia5gxgkwnqjleukqnt40nbqwk.lambda-url.us-east-2.on.aws/',
				URL_PUBLIC_DOCUMENT: 'https://qa.cofepris.esime.org/sitio/validacion_documentos/',
				CONFIG: {
					GET: 'https://t5umaaqhrf.execute-api.us-east-2.amazonaws.com/qa/getConfig',
					SET: 'https://t5umaaqhrf.execute-api.us-east-2.amazonaws.com/qa/config'
				},
				AUTHENTICATION: {
					UPDATE_ME: 'https://t5umaaqhrf.execute-api.us-east-2.amazonaws.com/qa/updateMe',
					LOGOUT: 'https://t5umaaqhrf.execute-api.us-east-2.amazonaws.com/qa/logout',
					RSA_GET_LOGIN_MESSAGE: 'https://t5umaaqhrf.execute-api.us-east-2.amazonaws.com/qa/requestAccess',
					RSA_GET_IDENTITY_TOKEN: 'https://t5umaaqhrf.execute-api.us-east-2.amazonaws.com/qa/requestLogin',
					RSA_GET_ACCESS_TOKEN: 'https://t5umaaqhrf.execute-api.us-east-2.amazonaws.com/qa/requestAccessToken',
					WEBAUTHN_GET_AUTHENTICATOR_MESSAGE: 'https://t5umaaqhrf.execute-api.us-east-2.amazonaws.com/qa/registerAuthenticator',
					WEBAUTHN_AUTHENTICATOR_ACTIVATE: 'https://t5umaaqhrf.execute-api.us-east-2.amazonaws.com/qa/activateAuthenticator',
					WEBAUTHN_AUTHENTICATOR_LOGIN: 'https://t5umaaqhrf.execute-api.us-east-2.amazonaws.com/qa/requestFidoLogin'
				},
				PAGOS: {
					REGISTRAR_LLAVES: 'https://ppp4psmb77.execute-api.us-east-2.amazonaws.com/qa/registrarLlavesPago',
					CONSULTAR_LLAVES: 'https://ppp4psmb77.execute-api.us-east-2.amazonaws.com/qa/obtenerLlavesPago'
				},
				ORGANIZATION: 'https://t5umaaqhrf.execute-api.us-east-2.amazonaws.com/qa/users',
				USERS_BY_ORGANIZATIVE_UNIT: 'https://t5umaaqhrf.execute-api.us-east-2.amazonaws.com/qa/getUsersFromUnidad',
				GET_USERS_BY_UUIDS: 'https://t5umaaqhrf.execute-api.us-east-2.amazonaws.com/qa/getUsersByUuids',
				LOGS: 'https://t5umaaqhrf.execute-api.us-east-2.amazonaws.com/qa/getBinnacle',
				GRAPQLH: {
					URL: 'https://7pjzo7m4hnccjng5jn2qxjdeeq.appsync-api.us-east-2.amazonaws.com/graphql'
				},
				RNEC: {
					BUSCAR: 'https://2axwz2uefbhumtabuz2rmsa6em0yjntc.lambda-url.us-east-2.on.aws/',
					OBTENER_ENSAYO: 'https://ijq5ap2l6nm4l3syjgl3khtdlm0jfaom.lambda-url.us-east-2.on.aws/'
				}
			},
			AWS_CAPTCHA: {
				API_KEY: '',
				URL_SCRIPT: ''
			}
		},
		// DRL es producción, cuidado con mover esta sección
		DRL: {
			AUTHENTICATION: {
				ORGANIZATION_GROUP: 'COFEPRIS',
				PUBLIC_GROUP: 'PUBLICO',
				RPID: 'https://digiprisregulacionenlinea.cofepris.gob.mx',
				JWT_ISSUER: 'cofepris-sso',
				JWK_PUBLIC: `-----BEGIN PUBLIC KEY-----
				MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEAlMxe9yJ+i6X3K0hc0HrH
				GIKa0VZZhfku7h0y0FctBXjki1TGV12HTmdFZZXGL9yy/zwNCPdO0wR8xxmMnIrw
				d4S956vMeGXP+WQdrCg5h6vSYEoB9oaNEG9nlF815ceRRpSvod28bv1BGkXw6GrM
				KeeJ5hs8dvvxoVZzoV3Fo2oLYNiYJcgniUMEQtzqi923SiC7tiAc5H7VHJIloUD/
				eFrtqvoOdHkoweRnEmHxJ1rqsXyqfYSXKPnsbQtsoSEDfAjW6LWaAce5RO3IUzW8
				ookNhIc/K4lmjiJniiXHCGrguhkSZzac6sBr6J2ro4SjBRrky3tYcWNPn+o9L1bL
				THay5kwRXtu5rHb7eOhgMqx4Nr27ca6sXxEP/7+k2nH8fIf4sLDV83WS7dCOKgc+
				fwFSwQIEwp2FA/7mn4CpsRVlHePIW41sYgHzo51/Y00LAW5KbClDvwLjilz0Uok0
				tgCQGM5KvxSsPxIEy3xKAzwiGp1oNNI9ySI09HhTGUCUnbEUobK1+vCVDvJirVrX
				xCKFFgVsCoE/yFk5YN1mhC080nH2G/N193GYRoT828XObtCj2A16yiVtC91AjHl+
				1jlTTrHJNAFpsrgedSWLUp0rn8TCcyTNHRgUTn5r7c7fM9X6Eh+WR4HwuY0GkE9R
				QjryPbYvKy/ouJXEMzSbIs0CAwEAAQ==
				-----END PUBLIC KEY-----`,
				JWT_ACCESS_TOKEN_REMAINING_SECONDS_RENEWAL: 60 * 2,
				WEBAUTHN_USERNAME_POSTFIX: '-DRL'
			},
			SESSION: {
				MAX_IDLE_TIME_SECONDS: 60 * 15,
				ALERT_IDLE_TIME_SECONDS: 60 * 3
			},
			API_SERVICES: {
				GET_PUBLIC_DOCUMENT: 'https://ly6twhf6cbsq5lic6qzxqxo4ve0obpii.lambda-url.us-east-1.on.aws/',
				URL_PUBLIC_DOCUMENT: 'https://digiprisregulacionenlinea.cofepris.gob.mx/sitio/validacion_documentos/',
				CONFIG: {
					GET: 'https://tqjd5txxqk.execute-api.us-east-1.amazonaws.com/drl/getConfig',
					SET: 'https://tqjd5txxqk.execute-api.us-east-1.amazonaws.com/drl/config'
				},
				AUTHENTICATION: {
					UPDATE_ME: 'https://tqjd5txxqk.execute-api.us-east-1.amazonaws.com/drl/updateMe',
					LOGOUT: 'https://tqjd5txxqk.execute-api.us-east-1.amazonaws.com/drl/logout',
					RSA_GET_LOGIN_MESSAGE: 'https://tqjd5txxqk.execute-api.us-east-1.amazonaws.com/drl/requestAccess',
					RSA_GET_IDENTITY_TOKEN: 'https://tqjd5txxqk.execute-api.us-east-1.amazonaws.com/drl/requestLogin',
					RSA_GET_ACCESS_TOKEN: 'https://tqjd5txxqk.execute-api.us-east-1.amazonaws.com/drl/requestAccessToken',
					WEBAUTHN_GET_AUTHENTICATOR_MESSAGE: 'https://tqjd5txxqk.execute-api.us-east-1.amazonaws.com/drl/registerAuthenticator',
					WEBAUTHN_AUTHENTICATOR_ACTIVATE: 'https://tqjd5txxqk.execute-api.us-east-1.amazonaws.com/drl/activateAuthenticator',
					WEBAUTHN_AUTHENTICATOR_LOGIN: 'https://tqjd5txxqk.execute-api.us-east-1.amazonaws.com/drl/requestFidoLogin'
				},
				PAGOS: {
					REGISTRAR_LLAVES: 'https://ivlf7k8e69.execute-api.us-east-1.amazonaws.com/drl/registrarLlavesPago',
					CONSULTAR_LLAVES: 'https://ivlf7k8e69.execute-api.us-east-1.amazonaws.com/drl/obtenerLlavesPago'
				},
				ORGANIZATION: 'https://tqjd5txxqk.execute-api.us-east-1.amazonaws.com/drl/users',
				USERS_BY_ORGANIZATIVE_UNIT: 'https://tqjd5txxqk.execute-api.us-east-1.amazonaws.com/drl/getUsersFromUnidad',
				GET_USERS_BY_UUIDS: 'https://tqjd5txxqk.execute-api.us-east-1.amazonaws.com/drl/getUsersByUuids',
				LOGS: 'https://tqjd5txxqk.execute-api.us-east-1.amazonaws.com/drl/getBinnacle',
				GRAPQLH: {
					URL: 'https://x3wa3inx5jf2zmwhwscspst7nm.appsync-api.us-east-1.amazonaws.com/graphql'
				},
				RNEC: {
					BUSCAR: 'https://6boaakxsteovwxn5itrlpanoiy0athsq.lambda-url.us-east-1.on.aws/',
					OBTENER_ENSAYO: 'https://y2re4thliqvyj427ka5i7yoafa0dmrdh.lambda-url.us-east-1.on.aws/'
				}
			},
			AWS_CAPTCHA: {
				API_KEY: '',
				URL_SCRIPT: ''
			}
		},
		STAGE: {
			AUTHENTICATION: {
				ORGANIZATION_GROUP: 'COFEPRIS',
				PUBLIC_GROUP: 'PUBLICO',
				RPID: 'TBD',
				JWT_ISSUER: 'cofepris-sso',
				JWK_PUBLIC: `-----BEGIN PUBLIC KEY-----
                MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEAtTW1T/muQ8hW0ZOjlcFP
                bVUoKgT/NGiNADceplhzxnWMbs4pzsTq0NMzOprwumW/a4q2VV9NiogLeUxA56IB
                dkPzC+kbDxgyfoeyKVDceHqr38vTiYogoOm4vcwBvZvOO0PhCSzy92/pGMwFxC6S
                0qZ6rEpbs6/57V/UrECgj9btXj+Lfxy0+jzuwTMR1b65KpFS18meb44G4jpBk3Fb
                rP7uU+XSREmiOVUmhTWzIKO0meh0f25V9v2rwXoG6/1jNPImQzTJb1ybMgZKYapI
                VDcbsHYx33kj8hmQFOpJ4CTpM67RQG28CVkyTWDflHGGOf5u43GfIToB7smf9iVc
                pWyZTLgOhXnISiT1Eim0j3BRLbkBXvLQ4rJBUxCLFPTKVP73QRXly5wWYQWN+NE2
                IwB7XxGMMEUm54IrQXH9v6on3YynQICLPzP3k+klrvgW16kxgNwQcDn0zqPY65bT
                XidDus5eU6Id41868Tk5ouXGW46dZtvAxgHotQYfZUy4Y9j/7tzx6sHJTJqWeX+a
                0ZVtTtpiqPlu3WbWATku/Y7cZZMUnfZEga/LLdlzbopiKqZ2CBp6nAPsGyEW1xiW
                2OC42VwgH9U+OagqpoYrZ2+vcahig71KN4v2feaXwYpXdlKXS1PfMQeZU+i6Wmmc
                VMid+QaMaM/XfS8frNzXv8cCAwEAAQ==
                -----END PUBLIC KEY-----`,
				JWT_ACCESS_TOKEN_REMAINING_SECONDS_RENEWAL: 60 * 2,
				WEBAUTHN_USERNAME_POSTFIX: '-STAGE'
			},
			SESSION: {
				MAX_IDLE_TIME_SECONDS: 60 * 15,
				ALERT_IDLE_TIME_SECONDS: 60 * 3
			},
			API_SERVICES: {
				GET_PUBLIC_DOCUMENT: 'https://kctczuu5j2lw4q4qdffeenhcdi0ysfjt.lambda-url.us-east-1.on.aws/',
				URL_PUBLIC_DOCUMENT: 'https://stage.cofepris.esime.org/sitio/validacion_documentos/',
				CONFIG: {
					GET: 'https://cuuyrgdr09.execute-api.us-east-1.amazonaws.com/stage/getConfig',
					SET: 'https://cuuyrgdr09.execute-api.us-east-1.amazonaws.com/stage/config'
				},
				AUTHENTICATION: {
					UPDATE_ME: 'https://cuuyrgdr09.execute-api.us-east-1.amazonaws.com/stage/updateMe',
					LOGOUT: 'https://cuuyrgdr09.execute-api.us-east-1.amazonaws.com/stage/logout',
					RSA_GET_LOGIN_MESSAGE: 'https://cuuyrgdr09.execute-api.us-east-1.amazonaws.com/stage/requestAccess',
					RSA_GET_IDENTITY_TOKEN: 'https://cuuyrgdr09.execute-api.us-east-1.amazonaws.com/stage/requestLogin',
					RSA_GET_ACCESS_TOKEN: 'https://cuuyrgdr09.execute-api.us-east-1.amazonaws.com/stage//requestAccessToken',
					WEBAUTHN_GET_AUTHENTICATOR_MESSAGE: 'https://cuuyrgdr09.execute-api.us-east-1.amazonaws.com/stage/registerAuthenticator',
					WEBAUTHN_AUTHENTICATOR_ACTIVATE: 'https://cuuyrgdr09.execute-api.us-east-1.amazonaws.com/stage/activateAuthenticator',
					WEBAUTHN_AUTHENTICATOR_LOGIN: 'https://cuuyrgdr09.execute-api.us-east-1.amazonaws.com/stage/requestFidoLogin'
				},
				PAGOS: {
					REGISTRAR_LLAVES: '...',
					CONSULTAR_LLAVES: '...'
				},
				ORGANIZATION: 'https://cuuyrgdr09.execute-api.us-east-1.amazonaws.com/stage/users',
				USERS_BY_ORGANIZATIVE_UNIT: 'https://cuuyrgdr09.execute-api.us-east-1.amazonaws.com/stage/getUsersFromUnidad',
				GET_USERS_BY_UUIDS: 'https://cuuyrgdr09.execute-api.us-east-1.amazonaws.com/stage/getUsersByUuids',
				LOGS: 'https://cuuyrgdr09.execute-api.us-east-1.amazonaws.com/stage/getBinnacle',
				GRAPQLH: {
					URL: 'https://57l4gp7pzzbgtdcstmxvcsnboe.appsync-api.us-east-1.amazonaws.com/graphql'
				},
				RNEC: {
					BUSCAR: 'https://lyrwawhtr27hfjubxrlxq2goji0ohuaj.lambda-url.us-east-1.on.aws/',
					OBTENER_ENSAYO: 'https://ypzcwwdutvu3yufyy72ahe4uiu0nbwdi.lambda-url.us-east-1.on.aws/'
				}
			},
			AWS_CAPTCHA: {
				API_KEY: '',
				URL_SCRIPT: ''
			}
		}
	}
}

export const setEnvironment = (environment: EnvironmentType): void => {
	if (Object.values(EnvironmentType).includes(environment)) {
		config.ENVIRONMENT = environment
	}
}

export default config
